import { render, staticRenderFns } from "./CampaignInfo.vue?vue&type=template&id=a6a3ac84&scoped=true"
import script from "./CampaignInfo.vue?vue&type=script&setup=true&lang=ts"
export * from "./CampaignInfo.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CampaignInfo.vue?vue&type=style&index=0&id=a6a3ac84&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6a3ac84",
  null
  
)

export default component.exports