import * as Types from '../../@types/graphql-types';

import gql from 'graphql-tag';
import { WatchNowOfferFragmentDoc } from '../../pages/graphql/fragments/WatchNowOffer.fragment';
export type SetInSeenlistMutationVariables = Types.Exact<{
	input: Types.SetInSeenlistInput;
	country: Types.Scalars['Country']['input'];
	language: Types.Scalars['Language']['input'];
	includeUnreleasedEpisodes: Types.Scalars['Boolean']['input'];
	watchNowFilter: Types.WatchNowOfferFilter;
	platform?: Types.Platform;
}>;

export type SetInSeenlistMutation = {
	__typename?: 'Mutation';
	setInSeenlist: {
		__typename?: 'SetInSeenlistPayload';
		title:
			| {
					__typename?: 'Episode';
					id: string;
					seenlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
					show: {
						__typename?: 'Show';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						seenState: { __typename?: 'ShowSeenState'; progress: number; caughtUp: boolean };
						watchNextEpisode?: {
							__typename?: 'Episode';
							id: string;
							objectId: number;
							objectType: Types.ObjectType;
							offerCount: number;
							season: {
								__typename?: 'Season';
								id: string;
								content: { __typename?: 'SeasonContent'; fullPath: string; posterUrl?: string | null };
								seenState: {
									__typename?: 'ShowSeenState';
									releasedEpisodeCount: number;
									seenEpisodeCount: number;
									progress: number;
								};
							};
							content: {
								__typename?: 'EpisodeContent';
								title: string;
								episodeNumber: number;
								seasonNumber: number;
								upcomingReleases?: Array<{
									__typename?: 'UpcomingRelease';
									releaseDate?: any | null;
									label: Types.UpcomingReleaseLabel;
								}>;
							};
							watchNowOffer?: {
								__typename: 'Offer';
								id: string;
								standardWebURL?: string | null;
								preAffiliatedStandardWebURL?: string | null;
								streamUrl?: string | null;
								retailPrice?: string | null;
								retailPriceValue?: number | null;
								lastChangeRetailPriceValue?: number | null;
								currency?: string | null;
								presentationType: Types.PresentationType;
								monetizationType: Types.MonetizationType;
								availableTo?: any | null;
								dateCreated?: any | null;
								newElementCount?: number | null;
								package: {
									__typename?: 'Package';
									id: string;
									icon: string;
									packageId: number;
									clearName: string;
									shortName: string;
									technicalName: string;
									iconWide: string;
									hasRectangularIcon: boolean;
								};
							} | null;
						} | null;
					};
					season: {
						__typename?: 'Season';
						id: string;
						seenState: { __typename?: 'ShowSeenState'; progress: number; caughtUp: boolean };
					};
			  }
			| {
					__typename?: 'Movie';
					id: string;
					seenlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
					watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
			  }
			| {
					__typename?: 'Season';
					id: string;
					show: {
						__typename?: 'Show';
						id: string;
						seenState: { __typename?: 'ShowSeenState'; progress: number; caughtUp: boolean };
					};
					episodes: Array<{
						__typename?: 'Episode';
						id: string;
						seenlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
					}>;
					seenState: { __typename?: 'ShowSeenState'; progress: number; caughtUp: boolean };
			  }
			| {
					__typename?: 'Show';
					id: string;
					seenState: { __typename?: 'ShowSeenState'; progress: number; caughtUp: boolean };
					seasons: Array<{
						__typename?: 'Season';
						id: string;
						seenState: { __typename?: 'ShowSeenState'; progress: number };
						episodes: Array<{
							__typename?: 'Episode';
							id: string;
							seenlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						}>;
					}>;
			  };
	};
};

export const SetInSeenlistDocument = gql`
	mutation SetInSeenlist(
		$input: SetInSeenlistInput!
		$country: Country!
		$language: Language!
		$includeUnreleasedEpisodes: Boolean!
		$watchNowFilter: WatchNowOfferFilter!
		$platform: Platform! = WEB
	) {
		setInSeenlist(input: $input) {
			title {
				id
				... on Movie {
					seenlistEntry {
						createdAt
					}
					watchlistEntryV2 {
						createdAt
					}
				}
				... on Show {
					seenState(country: $country) {
						progress
						caughtUp
					}
					seasons {
						id
						seenState(country: $country) {
							progress
						}
						episodes {
							id
							seenlistEntry {
								createdAt
							}
						}
					}
				}
				... on Season {
					show {
						id
						seenState(country: $country) {
							progress
							caughtUp
						}
					}
					episodes {
						id
						seenlistEntry {
							createdAt
						}
					}
					seenState(country: $country) {
						progress
						caughtUp
					}
				}
				... on Episode {
					seenlistEntry {
						createdAt
					}
					show {
						id
						objectId
						objectType
						seenState(country: $country) {
							progress
							caughtUp
						}
						watchNextEpisode(country: $country, includeUnreleasedEpisodes: $includeUnreleasedEpisodes) {
							id
							objectId
							objectType
							offerCount(country: $country, platform: $platform)
							season {
								id
								content(country: $country, language: $language) {
									fullPath
								}
								seenState(country: $country) {
									releasedEpisodeCount
									seenEpisodeCount
									progress
								}
							}
							season {
								id
								content(country: $country, language: $language) {
									posterUrl
								}
							}
							content(country: $country, language: $language) {
								title
								episodeNumber
								seasonNumber
								upcomingReleases(releaseTypes: [DIGITAL]) @include(if: $includeUnreleasedEpisodes) {
									releaseDate
									label
								}
							}
							watchNowOffer(country: $country, platform: $platform, filter: $watchNowFilter) {
								...WatchNowOffer
							}
						}
					}
					season {
						id
						seenState(country: $country) {
							progress
							caughtUp
						}
					}
				}
			}
		}
	}
	${WatchNowOfferFragmentDoc}
`;
